// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leefstijlcoaching-tsx": () => import("./../../../src/pages/leefstijlcoaching.tsx" /* webpackChunkName: "component---src-pages-leefstijlcoaching-tsx" */),
  "component---src-pages-powerwalking-tsx": () => import("./../../../src/pages/powerwalking.tsx" /* webpackChunkName: "component---src-pages-powerwalking-tsx" */),
  "component---src-pages-recepten-contentful-recipe-slug-tsx": () => import("./../../../src/pages/recepten/{ContentfulRecipe.slug}.tsx" /* webpackChunkName: "component---src-pages-recepten-contentful-recipe-slug-tsx" */),
  "component---src-pages-recepten-index-tsx": () => import("./../../../src/pages/recepten/index.tsx" /* webpackChunkName: "component---src-pages-recepten-index-tsx" */),
  "component---src-pages-trainingen-tsx": () => import("./../../../src/pages/trainingen.tsx" /* webpackChunkName: "component---src-pages-trainingen-tsx" */),
  "component---src-pages-voorwaarden-tsx": () => import("./../../../src/pages/voorwaarden.tsx" /* webpackChunkName: "component---src-pages-voorwaarden-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */)
}

